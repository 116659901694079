.project-carousel {}

.carousel .slider-wrapper {}

.carousel .thumb {}

.carousel {
    display: flex;
    justify-content: center;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid #000;
}

.carousel .thumb:focus {
    border: 3px solid #000;
    outline: none;
}

.carousel .carousel .thumb {
    border: 3px solid #fff;
}

.MuiDialog-container .MuiPaper-root {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}